import axios from 'axios';

export default {
    outcomes: null,
    resources: null,
    frequencies: null,

    async getOutcomeList(auth) {
      if(this.outcomes) {
        return this.outcomes;
      }
      else {
        const token = await auth.getTokenSilently();

        await axios.get(`${process.env.VUE_APP_BASE_URI}/coreitems`,{
          headers: {
            //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
            const data = response.data.data;
            if(data && data.coreitems) {
              this.outcomes = data.coreitems.map(item => {
                return { ...item, abbr:item.name.charAt(0).toUpperCase() };
              });
            }
        })
        .catch((error) => {
            this.outcomes = null;
            console.log("API error loading outcomes",error,error.response);
        });

        return this.outcomes;
      }
    },

    async getResourceList(auth) {
      if(this.resources) {
        return this.resources;
      }
      else {
        const token = await auth.getTokenSilently();

        await axios.get(`${process.env.VUE_APP_BASE_URI}/resources`,{
          headers: {
            //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
            const data = response.data.data;
            if(data && data.resources) {
              this.resources = data.resources.map(item => {
                return { ...item, abbr:item.name.charAt(0).toUpperCase() };
              });
            }
        })
        .catch((error) => {
            this.resources = null;
            console.log("API error loading resources",error,error.response);
        });

        return this.resources;
      }
    },

    async getFrequencyList(auth) {
      if(this.frequencies) {
        return this.frequencies;
      }
      else {
        const token = await auth.getTokenSilently();

        await axios.get(`${process.env.VUE_APP_BASE_URI}/frequency`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          console.log("Got frequencies:",response);
            this.frequencies = response.data;
        })
        .catch((error) => {
            this.frequencies = null;
            console.log("API error loading frequencies",error,error.response);
        });
        return this.frequencies;
      }
    },

    getDifficultyList() {
      // TODO: verify final values or pull from API
      return [{id:1,label:'Easy'},{id:2,label:'Medium'},{id:3,label:'Difficult'}];
    },

};
