<template>
    <div class="app-container">

      <activity-list actionLabel="Add activity" @action="newActivity" @select="viewActivity" />

    </div>
</template>

<script>
    import ActivityList from '@/views/components/ActivityList';

    export default {
        name: 'activities',
        components: {
          ActivityList,
        },
        data() {
            return {
            };
        },
        methods: {
            newActivity() {
              this.$router.push(`/activity/new`)
            },

            viewActivity(activity) {
              this.$router.push(`/activity/${activity.id}`)
            },
        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
